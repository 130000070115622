/* The inputElement */
.inputElement {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 6px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.inputElement input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.inputElement:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.inputElement input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.inputElement input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.inputElement .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}



.radioElement {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 10px 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioElement input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radioCustom {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioElement:hover input~.radioCustom {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioElement input:checked~.radioCustom {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCustom:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioElement input:checked~.radioCustom:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioElement .radioCustom:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.cp-interest-filter-btn {
	background-color:#00c4ff;
	border-radius:15px;
	border:1px solid #00c4ff;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family: 'Lato sans-serif';
	font-size:13px;
	padding:4px 13px;
	text-decoration:none;
}
.cp-interest-filter-btn:hover {
	background-color:#5edcff;
}
.cp-interest-filter-btn:active {
	position:relative;
	top:1px;
}