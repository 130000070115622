.dropbtn {
  /* background-color: #4CAF50; */
  /* color: white;
    padding: 16px;
    font-size: 16px; */
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  border-radius: 2px;
  background-color: #f9f9f9;
  right: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* .dropdown-content div {
    color: black;
    display: block;
  }
  .dropdown-content div:hover {
    color: white;
    display: block;
  } */

/* .dropdown-content a:hover {background-color: #f1f1f1} */

/* .dropdown:hover .dropdown-content {
    display: block;
  } */

.react-calendar__navigation>button.react-calendar__navigation__label {
  pointer-events: none;
}

.chq-atc {
  display: inline-block;
  position: relative;
  border: blueviolet;
}

.chq-atc--button {
  background-color: #00C4FF;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #6a89af;
  cursor: pointer;
  display: inline;
font-family: 'Lato sans-serif';
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 10px 5rem;
  color: white;

}

.chq-atc--button:focus {
  background-color: #00C4FF;
}

.chq-atc--button:hover {
  background-color: #00C4FF;
}



.chq-atc--button path {
  fill: skyblue;
}

.chq-atc--dropdown {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.chq-atc--dropdown a {
  color: #6a89af;
  display: block;
  padding: 8px 15px;
  text-decoration: none;
}

.chq-atc--dropdown a:hover {
  background-color: #eaeaea;
}

.chq-atc--button svg {
  vertical-align: text-bottom;
  display: none;
}

div#mui-1 {
  position: relative;
  z-index: 0;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}