.backgroundModal {
    background: url("../Images/modalFrame.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.responsive-large-screen {
    top: 250px;
    right: 10%;
}

.keep-in-center{
    margin-left: 0px !important;
}

@media only screen and (min-width: 1800px) {
    .responsive-large-screen {
        top: 250px;
        /* position: relative; */
        /* width: 90%; */
        right:12%;
    }
}

@media only screen and (min-width: 2000px) {
    .responsive-large-screen {
        top: 250px;
        /* position: relative; */
        width: 22%;
        right:15%;
    }

}

@media only screen and (min-width: 1950px) {
    .keep-in-center{
        margin-left: 8px !important;
    }
}

@media only screen and (min-width: 2000px) {
    .keep-in-center{
        margin-left: 0px !important;
    }
}

@media only screen and (min-width: 2100px) {
    .responsive-large-screen {
        top: 250px;
        /* position: relative; */
        width: 20%;
        right:20%;
    }
}

@media only screen and (min-width: 2400px) {
    .responsive-large-screen {
        top: 0px;
        position: relative;
        width: 100%;
        /* right:12%; */
    }
}

@media only screen and (min-width: 2100px) {
    .keep-in-center{
        margin-left: 5px !important;
    }
}

@media only screen and (min-width: 1050px) {
    .responsive-text {
        margin-left: "150px";
    }
}

@media only screen and (min-width: 1050px) {
    .responsive-text-two {
        margin-left: "75px";
    }
}
