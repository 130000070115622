.text-style {
    background-color: white;
    width:100px;
}

.text-style:hover {
    background-color: rgb(34 211 238);
    color:white;
}


@media only screen and (max-width: 600px) {
    .text-style {
        background-color: white;
        width:100px;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 992px) {
    .text-style {
        background-color: white;
        width:130px;
        margin-left: 10px;
    }
}