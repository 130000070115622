.line {
    border-left: 1px solid #808080;
    height: 50px;
    opacity: 0.2;
}

/* .button {
    font-family: "Cerebri Sans Pro";
} */
.button {
    font-family: 'Lato sans-serif'
}

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal {
    overflow: auto;
}

.modal,
.overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
    z-index: 20
}

.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.logo-cp {
    width: 140px;
    max-width: 140px;
}

.nav-item {
    font-size: 0.8rem;
}

@media only screen and (min-width: 1200px) {
    .nav-item {
        font-size: 1rem;
    }

    .nav-item-big{
        font-size: 1.07rem;
    }

    .logo-cp {
        width: 210px;
        max-width: 210px;
    }
}
    .submenu {
    @media (max-width: 400px) {
      width: 250px;
    }
  
    @media (min-width: 401px) and (max-width: 768px) {
      width: 350px;
    }
  
    @media (min-width: 769px) {
      width: 480px;
    }
  }

  .same-width {
    width: 250px;
  }

  @media only screen and (max-width: 350px) {
    .smImage {
        height: 20%;
        width: 50px;
    }
  }
  
  
  .cp-login-btn {
	box-shadow:inset 0px 1px 3px 0px #00c4ff;
	background:linear-gradient(to bottom, #00c4ff 5%, #00c4ff 100%);
	background-color:#00c4ff;
	border-radius:8px;
	border:1px solid #00c4ff;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:'Lato sans-serif';
	font-size:17px;
	padding:9px 17px;
	text-decoration:none;
}
.cp-login-btn:hover {
	background:linear-gradient(to bottom, #00c4ff 5%, #00c4ff 100%);
	background-color:#00c4ff;
}
.cp-login-btn:active {
	position:relative;
	top:1px;
}

