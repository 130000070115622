@media screen and (min-width: 992px) {
    .heading {
        width: 50%;
        margin: 0 auto;
    }
}
@media (max-width: 768px) {
    .flex-col {
      flex-direction: column;
    }

    .flex-row {
      flex-direction: row;
    }

    .justify-between {
      justify-content: space-between;
    }

    .md\:ml-4 {
      margin-left: 0 !important;
      margin-top: 1rem;
    }
  }