


@tailwind base;
@tailwind components;
@tailwind utilities;


/* replace the Lato-Regular */
@font-face {
font-family: 'Lato sans-serif';
  src: local("Lato Regular"),
    url("./fonts/Lato-ExtraBold/lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}


@font-face {
  font-family: "Lato Extra Bold";
  src: local("Lato Extra Bold"),
    url("./fonts/Lato-ExtraBold/lato/Lato-ExtraBold.ttf") format("truetype");
  font-weight: normal;
}
/* end */


@font-face {
 font-family: "Lato Bold";
  src: local("Lato Extra Bold"),
    url("./fonts/Lato-ExtraBold/lato/Lato-ExtraBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Lato Bold";
  src: local("Lato Bold"), url("./fonts/Lato-ExtraBold/lato/Lato-Bold.ttf") format("truetype");
  font-weight: normal;
}



@font-face {
    font-family: "Lato";
  src: local("Lato Light"),
    url("./fonts/Lato-ExtraBold/lato/Lato-Light.ttf") format("truetype");
  font-weight: normal;
}





body {
font-family: 'Lato sans-serif';
  /* font-family: 'Lato'; */

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.myButton {
	background-color:#00c4ff;
	-webkit-border-radius:28px;
	-moz-border-radius:28px;
	border-radius:28px;
	border:1px solid #00c4ff;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
   font-family:  'Lato sans-serif';
	font-size:20px;
	font-weight:bold;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #00c4ff;
}
.myButton:hover {
	background-color:#5edcff;
}
.myButton:active {
	position:relative;
	top:1px;
}
.btn_font_family{
    font-family:  'Lato sans-serif' !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.MuiTooltip-tooltip {
  font-family:  'Lato sans-serif' !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.react-calendar__month-view__weekdays__weekday{
   font-family:  'Lato sans-serif' !important;
}
.MuiStepIcon-text{
   font-family:  'Lato sans-serif' !important;

}
.p-FieldLabel{
   font-family:  'Lato sans-serif' !important;

}
.MuiTableCell-root{
   font-family:  'Lato sans-serif' !important;

}
.Label{
   font-family:  'Lato sans-serif' !important;

}
#payment-element{
   font-family:  'Lato sans-serif' !important; 
}
.react-calendar__tile {
 font-family:  'Lato sans-serif' !important;
}

.MuiStepLabel-label {
 font-family:  'Lato sans-serif' !important;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(105, 196, 219);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(105, 196, 219);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 196, 219);
}

.css-1hv8oq8-MuiStepLabel-label{
font-family: 'Lato sans-serif'!important ;
}


.catagoryBackground {
  background: rgb(0, 196, 255);
  background: radial-gradient(circle, rgba(0, 196, 255, 1) 0%, rgba(0, 196, 233, 1) 100%);
}

input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
