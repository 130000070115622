.calender {
   border: none;
}

.available {
   border: 1px solid blue;
   background-color: #00C4FF;
   color: white;
   border-radius: 30px;
   /* width: 30px; */
   height: 50px;
}

.notAvailable {
   border: 1px solid blue;
   background-color: #ffffff;
   color: rgb(0, 0, 0);
   border-radius: 30px;
   height: 50px;
   margin: 2px;
}

.react-calendar__navigation>button.react-calendar__navigation__label {
   pointer-events: none;
}

.back {
   background-color: white;
}

/* rounded-full bg-blue-300  border-2 border-blue-300 text-white w-12 h-12 m-1 */