#myVideo123 {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    min-width: 100%;
    /* min-height: 100%;  */
    max-height: 100%;
    padding: 2%;
  }

  .video {
   min-height: 1000px !important;
   min-width: 600px !important
  }
  .content123 {
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }


  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  