.course-detail-btn {
	background-color:#00c4ff;
	-webkit-border-radius:6px;
	-moz-border-radius:6px;
	border-radius:6px;
	border:1px solid #00c4ff;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:18px;
	padding:12px 45px;
	text-decoration:none;
}
.course-detail-btn:hover {
	background-color:#29d1ff;
}
.course-detail-btn:active {
	position:relative;
	top:1px;
}