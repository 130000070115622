@media screen and (max-width: 480px) {
    .text-sm {
      font-size: 11px !important;
    }
}

@media screen and (max-width: 375px) {
  .text-sm {
    font-size: 9px !important;
  }
}
