#myVideo12 {
    position: fixed;
    width: 15%;
    height: 20%;
    bottom: 25%;
    right: 2%;
  }
  .parent {
    padding: 5%;
  }
  .content123 {
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }
  