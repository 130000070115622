.blur-content {
    /* background-color: red; */
    /* filter: blur(10px);
    pointer-events: none; */

}

/* .box-center {
    margin-left: 25px;
} */

@media only screen and (min-width: 1500px) {
    .box-center {
        margin-left: 140px;
    }
}
